<template>
    <div class="container-operator-mode">
        <div class="operator-mode-header">
            {{ $t("Operator Mode") }}
        </div>

        <b-row class="mt-5">
            <b-col cols="12" lg="4" xl="4">
                <div
                    class="card-operator-mode user"
                    @click="redirectPage('change-account')"
                >
                    <b-avatar
                        class="user-photo avatar-container"
                        v-if="userData.photo"
                        variant="light-primary"
                        :src="userData.photo"
                    />

                    <b-avatar
                        variant="light-secondary"
                        class="avatar-container"
                        v-else
                    >
                        <span class="avatar-name">
                            {{
                                `${userData.first_name
                                    .substring(0, 1)
                                    .toUpperCase()}${userData.last_name
                                    .substring(0, 1)
                                    .toUpperCase()}`
                            }}
                        </span>
                    </b-avatar>

                    <span class="card-operator-title">
                        {{ `${userData.first_name} ${userData.last_name}` }}
                    </span>

                    <span class="orders-subtitle">
                        {{ userData.job_role }}
                    </span>

                    <span class="orders-subtitle">
                        {{ userData.employee_id }}
                    </span>
                </div>
            </b-col>

            <b-col
                cols="12"
                lg="4"
                xl="4"
                class="card-orders"
                @click="redirectPage('operator/orders')"
            >
                <div class="card-operator-mode bottom">
                    <div class="card-icon">
                        <IconOperatorOrders></IconOperatorOrders>
                    </div>

                    <div class="box-titles">
                        <span class="card-operator-title">
                            {{ $t("Orders") }}
                        </span>

                        <span class="orders-subtitle">
                            {{ $t("Operator.CardOrdersSubTitle") }}
                        </span>
                    </div>
                </div>
            </b-col>

            <b-col cols="12" lg="4" xl="4" class="card-barcode">
                <div
                    class="card-operator-mode bottom mb-3"
                    @click="redirectPage('operator/barcode')"
                >
                    <div class="card-icon">
                        <IconOperatorBarcode></IconOperatorBarcode>
                    </div>

                    <div class="box-titles">
                        <span class="card-operator-title">
                            {{ $t("Operator.Barcode.Header") }}
                        </span>

                        <span class="orders-subtitle">
                            {{ $t("Operator.CardBarcodeSubTitle") }}
                        </span>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { BCol, BRow, BAvatar } from "bootstrap-vue";
import { mapMutations } from "vuex";

export default {
    components: {
        BCol,
        BRow,
        BAvatar,
        IconOperatorOrders: () =>
            import("@/assets/images/icons/operator-orders.svg"),
        IconOperatorBarcode: () =>
            import("@/assets/images/icons/operator-barcode.svg"),
    },
    data() {
        return {
            prefix:
                localStorage.getItem("prefix") ||
                this.$router.currentRoute.params.prefix,
            userData: JSON.parse(localStorage.getItem("userData")),
        };
    },
    methods: {
        ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
        redirectPage(route) {
            let prefix =
                localStorage.getItem("prefix") ||
                this.$router.currentRoute.params.prefix;

            this.$router.push({ path: `/${prefix}/${route}` });
        },
    },
    created() {
        this.UPDATE_FLAG_SHOW_OVERLAY(false);
    },
};
</script>

<style lang="scss">
.container-operator-mode {
    .operator-mode-header {
        width: 100vw;
        padding: 16px 29px;
        position: fixed;
        margin-top: -104px;
        margin-left: -28px;
        display: flex;
        align-items: center;

        font-family: "Raleway";
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;

        background: #ffffff;
        color: #4c4541;
        box-shadow: 0px 8px 30px rgba(58, 46, 38, 0.1);
        z-index: 1;
        border-top: 1px solid #cfc4be;
    }

    .card-operator-mode {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 16px;
        text-align: center;
        background: #fafafa;
        border: 1px solid #cfc4be;
        box-shadow: 0px 4px 24px rgba(58, 46, 38, 0.1);
        border-radius: 24px;
        height: 438px;

        &:hover {
            cursor: pointer;
            background: #ffede2;
            border: 1px solid #974900;
            box-shadow: 0px 4px 24px rgba(58, 46, 38, 0.1);
        }

        &:active {
            background: #ffdbc4;
        }
    }

    .card-icon {
        border: 1px solid #974900;
        border-radius: 6px;
        padding: 8px;
        width: 72px;
        height: 72px;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .card-operator-title {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 38px;
        color: #4c4541;
        margin-top: 16px;
    }

    .orders-subtitle {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #998f8a;
    }

    .avatar-container {
        width: 100px;
        height: 100px;

        .avatar-name {
            font-size: 36px;
            line-height: 50px;
        }
    }

    .box-titles {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 991px) {
    .container-operator-mode {
        .card-operator-mode {
            height: 241.67px;
        }
    }

    .card-orders,
    .card-barcode {
        margin-top: 24px !important;
    }
}

@media screen and (max-width: 768px) {
    .operator-mode-header {
        font-size: 36px !important;
        line-height: 50px !important;
    }
}

@media screen and (max-width: 575px) {
    .operator-mode-header {
        margin-left: -16px !important;
        padding: 16px !important;
    }
}

@media screen and (max-width: 480px) {
    .operator-mode-header {
        font-size: 18px !important;
        line-height: 26px !important;
    }

    .card-orders,
    .card-barcode {
        margin-top: 16px !important;
    }

    .card-operator-mode.user {
        height: 100% !important;
    }
}

@media (min-width: 769px) and (max-width: 991px) {
    .card-orders {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .card-barcode {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media screen and (max-width: 425px) {
    .container-operator-mode {
        .operator-mode-header {
            font-size: 18px;
        }

        .avatar-container {
            width: 60px !important;
            height: 60px !important;

            .avatar-name {
                font-size: 22px !important;
                line-height: 30px !important;
            }
        }

        .card-operator-mode {
            height: 166px;

            .user-photo {
                width: 60px !important;
                height: 60px !important;
            }

            .card-operator-title {
                font-size: 16px;
                line-height: 26px;
            }

            .orders-subtitle {
                font-size: 12px;
                line-height: 16px;
            }

            &.bottom {
                flex-direction: row;
                height: 90px;
                justify-content: start;
                gap: 18px;
            }

            .card-icon {
                width: 52px;
                height: 52px;

                svg {
                    width: 20px;
                    height: 20px;
                }

                svg path {
                    transform: scale(0.5);
                }
            }

            .box-titles {
                text-align: left;

                .card-operator-title {
                    margin-top: 0;
                }
            }
        }
    }
}
</style>
